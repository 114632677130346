import { Grid, Box, StepLabel, Typography, Breadcrumbs, Stepper, Step, MenuItem, FormControl, InputLabel, Select } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from 'react';
import AdminPartnerAddBusiness from './adminPartnerAdddBusiness';
import AdminDetailsSchool from './AdminDetailsSchool';
import AddAccountPartnerStepper from './AddAccountStepper';
import OutletPartnerList from './OutletListPartner';
import AdmTermList from './AdmTermList';
import AdmTermUser from './AdmTernUser';
import HttpComponent from '../../../../School/MakeRequest';
import AddNormalBusiness from './AddNormalBusiness';

export default function AddBusinesssStepper() {
    const [selectedBusinessType, setSelectedBusinessType] = useState('');
    const [businessTypes, setBusinessTypes] = useState([]);
    const getBusinessTypes = () => {
        HttpComponent({
            method: 'GET',
            url: '/api/listBusinessCategory',
            body: null,
            token: localStorage.getItem('X-Authorization'),
        }).then((data) => {
            console.log(data, 'businessTypes')
            setBusinessTypes(data?.response?.category)
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        getBusinessTypes()
    }, [])

    const steps = selectedBusinessType === 'School/University/College' ? ['Business / Institution Details', 'ZPM School Admin', 'Outlets / Shop', 'Terminals', 'Terminal User', 'Account Number'] : ['Business Details', 'Business Owner Details', 'Account Number']
    const [activeStep, setActiveStep] = useState(() => {
        const storedStep = localStorage.getItem('activeStep');
        return storedStep ? parseInt(storedStep, 10) : 0;
    });


    useEffect(() => {
        const storedStep = localStorage.getItem('activeStep');
        if (storedStep) {
            setActiveStep(parseInt(storedStep, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeStep', activeStep.toString());
    }, [activeStep]);


    // steppper

    const breadcrumbs = [
        <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
        <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>
            {activeStep === 0 ? 'Business / Institution Details' : activeStep === 1 ? 'ZPM School Admin' : activeStep === 2 ? 'Outlets / Shop' : activeStep === 3 ? 'Terminals' : activeStep === 4 ? 'Terminal User' : activeStep === 5 ? 'Account Number' : 'Set-Up'}
        </Typography>
    ]

    // next or back

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handlePrev = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    const RenderStepDetails = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Box>
                        <AdminPartnerAddBusiness prev={handlePrev} next={handleNext} />
                    </Box>
                );
            case 1:
                return (
                    <Box>
                        <AdminDetailsSchool prev={handlePrev} next={handleNext} />
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <OutletPartnerList prev={handlePrev} next={handleNext} />
                    </Box>
                );
            case 3:
                return (
                    <Box>
                        <AdmTermList prev={handlePrev} next={handleNext} />
                    </Box>
                );
            case 4:
                return (
                    <Box>
                        <AdmTermUser prev={handlePrev} next={handleNext} />
                    </Box>
                );
            case 5:
                return (
                    <Box>
                        <AddAccountPartnerStepper />
                    </Box>
                );

            default:
                return null

        }

    }

    const OtherBusinessStepper = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Box>
                        <AddNormalBusiness prev={handlePrev} next={handleNext} selectedBusinessType={selectedBusinessType} />
                    </Box>
                );
            case 1:
                return (
                    <Box>
                        <AdminDetailsSchool prev={handlePrev} next={handleNext} selectedBusinessType={selectedBusinessType} />
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <AddAccountPartnerStepper />
                    </Box>
                );
            default:
                return null
        }
    }



    return (
        <Grid container width={'100%'} display={'flex'} flexDirection={'column'}>
            <Grid item mt={2} mb={4}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            {!selectedBusinessType &&
                <Grid item mt={2} mb={4} width={'50%'}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedBusinessType} label="Business Type" onChange={(e) => setSelectedBusinessType(e.target.value)}>
                            {businessTypes?.map((acc) => {
                                return <MenuItem value={acc?.categoryName}>{acc?.categoryName}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>}
            {!selectedBusinessType ? null :
                <Box component="div" width="100%">
                    <Stepper
                        activeStep={activeStep}
                        orientation="horizontal"
                        sx={{
                            '& .MuiStep-root': {
                                position: 'relative',
                            },
                            '& .MuiStepConnector-line': {
                                borderColor: 'rgba(0, 0, 0, 0.12)',
                                borderWidth: '1px',
                            },
                            '& .MuiStepLabel-root': {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            },
                            '& .MuiStepLabel-label': {
                                marginTop: '8px',
                                fontSize: '14px',
                            },
                            '& .MuiStepIcon-root': {
                                fontSize: '24px',
                                color: 'rgba(0, 0, 0, 0.38)',
                            },
                            '& .MuiStepIcon-root.Mui-active': {
                                color: '#032541',
                            },
                            '& .MuiStepIcon-root.Mui-completed': {
                                color: '#17ae7b',
                            },
                        }}
                    >
                        {steps?.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

            }
            {selectedBusinessType && selectedBusinessType === 'School/University/College' && <Box component="div">{RenderStepDetails()}</Box>}
            {selectedBusinessType && selectedBusinessType !== 'School/University/College' && <Box component="div">{OtherBusinessStepper()}</Box>}
        </Grid>
    )
}