import { Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { handleSetBusinessDetails } from "../../../../../features/businessAddPartner";
import GeoLocation from "../../../../../hooks/useLocation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import HttpComponent from "../../../../School/MakeRequest";
const baseUrl = process.env.REACT_APP_BASE_URL;


export default function AddNormalBusiness(props) {
    const [businessTypes, setBussinessType] = useState([])
    const [schoolTypeOptions, setschoolOptions] = useState([])
    const [selectedBusinessType, setSelectedBusinesType] = useState('')
    const [selectedSchoolOption, setSelectedSchoolOption] = useState('')
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedValue, setSelectedValue] = useState('');
    const [allCoutries, setAllCountries] = useState([])
    const [country, setCountry] = useState(null)
    const [schoolName, setSchoolName] = useState('')
    const [schoolEmail, setSchoolEmail] = useState('')
    const [schoolPhone, setSchoolPhone] = useState('')
    const [schoolDirector, setSchoolDirector] = useState('')
    const [schoolLocation, setSchoolLocation] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedBranch, setSelectedBranch] = useState('')
    const [salesPName, setSalesPName] = useState('')
    const [salesPCode, setSalesPCode] = useState('')



    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [allRegions, setAllRegions] = useState([])
    function getAllRegionsList() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/partnerregions`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setAllRegions(data?.response?.data?.filter(item => item?.status === 'Active'))
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllRegionsList()
    }, [])
    const [allBranches, setAllBranches] = useState([])
    function getAllBranchesList() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/allregionpartnerbranchesbyid?regionId=${selectedRegion}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setAllBranches(data?.response?.data?.filter(item => item?.status === 'Active'))
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllBranchesList()
    }, [selectedRegion])

    const getSchoolTypes = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_types`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": localStorage.getItem('X-Authorization'),
            },
        });
        const data = await response.json();
        setschoolOptions(data.data.map((school) => {
            return { value: school._id, label: school.schoolType }
        }
        ))
    }


    useEffect(() => {
        getSchoolTypes();
    }, [])

    // currency & location

    const allCountry = [{
        "name": {
            "common": "Kenya",
        },
        "currencies": {
            "KES": {
                "name": "Kenyan shilling",
                "symbol": "Sh"
            }
        }
    },
    {
        "name": {
            "common": "Uganda",
        },
        "currencies": {
            "UGX": {
                "name": "Ugandan shilling",
                "symbol": "Sh"
            }
        }
    },
    {
        "name": {
            "common": "Nigeria",
        },
        "currencies": {
            "NGN": {
                "name": "Nigerian naira",
                "symbol": "₦"
            }
        }
    }
    ]



    //console.log(allCountry , )
    const filteredCountries = allCountry?.filter(country => {
        const commonName = country.name.common;
        return commonName === "Kenya" || commonName === "Uganda" || commonName === "Nigeria";
    });

    // console.log(filteredCountries , 'fil')

    const currencies = filteredCountries.map(country => {
        const commonName = country.name.common;
        const currencyData = country.currencies[Object.keys(country.currencies)[0]];
        return {
            country: commonName,
            currency: currencyData.name,
            currencyCode: Object.keys(country.currencies)[0],
            currencySymbol: currencyData.symbol
        };
    });

    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        const returnCurreny = currencies?.find((currency) => currency.country === selectedValue)
        setCountry(selectedValue)
        setSelectedCountry(returnCurreny?.country);
        setSelectedCurrency(returnCurreny.currencyCode);

    };

    const [allSalesPersons, setAllSalespersons] = useState([])
    const [salesPerson , setSelectedSalesPerson] = useState(null)

    function getSalespersons() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getpartneraddsalesperson`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setAllSalespersons(data?.response?.data)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getSalespersons();
    }, [])


    const handleSalesPersonChange = (event) => {
        const selectedValue = event.target.value;
        const foundSalesP = allSalesPersons?.find((p) => p.code === selectedValue)
        setSelectedSalesPerson(selectedValue)
        if(foundSalesP){
            setSalesPName(foundSalesP?.salesName)
            setSalesPCode(foundSalesP?.code)
        }else{
            setSalesPName('')
            setSalesPCode('')
        }
    }

    const formdataToDispatch = {
        businessCategory: props?.selectedBusinessType,
        schoolType: selectedSchoolOption,
        currency: selectedCurrency,
        name: schoolName,
        partnerRegion: selectedRegion,
        partnerBranch: selectedBranch,
        salesPName: salesPName,
        salesPCode: salesPCode,
    }

    const handleDispatch = () => {
        dispatch(handleSetBusinessDetails(formdataToDispatch))
    }

    return (
        <Grid container direction={'column'}>
            <Grid item mt={2}>
                <Typography variant="h6" sx={{ color: '#032541' }}>
                    Set-Up Your Business Details
                </Typography>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <Grid container width={'100%'} alignItems={'center'} gap={2}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '', marginBottom: '10px' }}>Business Type</InputLabel>
                        <TextField style={{ width: '100%' }} value={props?.selectedBusinessType} disabled id="outlined" required />
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <Typography>Business Details</Typography>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '', marginBottom: '10px' }}>Business Name</InputLabel>
                        <TextField style={{ width: '100%' }} value={schoolName} onChange={(e) => setSchoolName(e.target.value)} id="outlined" required />
                    </Grid>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '', marginBottom: '10px' }}> Email</InputLabel>
                        <TextField style={{ width: '100%' }} value={schoolEmail} onChange={(e) => setSchoolEmail(e.target.value)} id="outlined" required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '', marginBottom: '10px' }}>Phone</InputLabel>
                        <PhoneInput
                            inputStyle={{ height: '55px', width: '100%' }}
                            required
                            country={'ke'}
                            enableSearch={true}
                            value={schoolPhone}
                            onChange={(phone) => setSchoolPhone(phone)}
                        />
                    </Grid>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '', marginBottom: '10px' }}>Director</InputLabel>
                        <TextField style={{ width: '100%' }} value={schoolDirector} onChange={(e) => setSchoolDirector(e.target.value)} id="outlined" required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Select Region</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedRegion}
                                label="Select Region"
                                onChange={(e) => setSelectedRegion(e.target.value)}
                            >
                                {allRegions?.map((region) => {
                                    return <MenuItem value={region?._id}>{region?.regionName}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item width={'49%'}>
                        {selectedRegion && (
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Select Branch</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedBranch}
                                    label="Select Branch"
                                    onChange={(e) => setSelectedBranch(e.target.value)}
                                >
                                    {allBranches?.map((acc) => {
                                        return <MenuItem value={acc?.branchCode}>{acc?.branchName}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        )}
                        {!selectedRegion && (
                            <Alert variant="outlined" severity="error">
                                Kindly Select Region
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={country} label="Select Country" onChange={handleCountryChange}>
                                {currencies?.map((acc) => {
                                    return <MenuItem value={acc?.country}>{acc?.country}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item width={'49%'}>
                        <TextField style={{ width: '100%' }} disabled value={selectedCurrency} name="currency" id="outlined" required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Select Sales Person</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={salesPerson} label="Select Sales Person" onChange={handleSalesPersonChange}>
                        {allSalesPersons?.map((acc) => {
                            return <MenuItem value={acc?.code}>{acc?.salesName}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '', marginBottom: '10px' }}>Sales Person Name </InputLabel>
                        <TextField style={{ width: '100%' }} value={salesPName} disabled id="outlined" required />
                    </Grid>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '', marginBottom: '10px' }}>Sales Person Code</InputLabel>
                        <TextField style={{ width: '100%' }} value={salesPCode} disabled id="outlined" required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <GeoLocation required={true} sx={{ width: '100%' }} selectedValue={selectedValue} onValueChange={(address) => setSelectedValue(address)} />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button
                    onClick={() => {
                        handleDispatch();
                        props?.next();
                    }}
                    disabled={!schoolDirector || !schoolEmail}
                    style={
                        !schoolDirector
                            ? { width: '146px', marginLeft: '10px', textTransform: 'inherit', height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' }
                            : { width: '146px', marginLeft: '10px', textTransform: 'inherit', height: '50px', color: '#fff', backgroundColor: '#032541' }
                    }
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );
}
